@page { size: A4;   width: 210mm;
  height: 297mm;
  margin: 0; }
@media print {
  .search-box {
    display: none;
  }
}
* {
  box-sizing: border-box;
}

body {
  width: 210mm;
  height: 297mm;
  margin: 21.2mm 0 22mm;
  font-family: 'Noto Sans JP', sans-serif;
}
